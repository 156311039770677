import { useStaticQuery } from 'gatsby';
import * as React from 'react';
import { Helmet } from 'react-helmet';

import { GlobalStyleEnglish } from '../components/global-style/GlobalStyleEnglish';
import { GlobalStyleHebrew } from '../components/global-style/GlobalStyleHebrew';
import { useSearchParams } from '../hooks/useSearchParams';

interface DefaultLayoutProps extends React.HTMLProps<HTMLDivElement> {
  children: React.ReactNode
  language: 'en' | 'he'
  title?: string
  description?: string
  path?: string
  article?: boolean
}

const DefaultLayout = (props: DefaultLayoutProps) => {
  const data = useStaticQuery(graphql`
    query SEOQuery {
      site {
        siteMetadata {
          title
          description
          siteUrl
        }
      }
    }
  `)

  const { title: defaultTitle, description: defaultDescription, siteUrl } = data.site.siteMetadata

  const title = props.title ? `${props.title} - Skilled` : defaultTitle
  const description = props.description || defaultDescription
  const url = props.path ? `${siteUrl}${props.path || '/'}` : siteUrl
  const image = `${siteUrl}/skilled-og.png`
  const imageWhatsapp = `${siteUrl}/skilled-og-whatsapp.png`

  useSearchParams()

  return (
    <>
      <Helmet title={title}>
        <html lang="en" />
        <meta name="description" content={description} />
        <meta
          name="keywords"
          content="developers, hire, programmers, israel, tel aviv, freelancers"
        />
        <meta name="image" content={image} />
        {url && <meta property="og:url" content={url} />}
        {props.article ? (
          <meta property="og:type" content="article" />
        ) : (
          <meta property="og:type" content="website" />
        )}
        {title && <meta property="og:title" content={title} />}
        <meta property="og:description" content={description} />

        <meta property="og:image" content={image} />
        <meta property="og:image:type" content="image/png" />
        <meta property="og:image:width" content="1200" />
        <meta property="og:image:height" content="630" />

        <meta property="og:image" content={imageWhatsapp} />
        <meta property="og:image:type" content="image/png" />
        <meta property="og:image:width" content="400" />
        <meta property="og:image:height" content="400" />

        <meta name="twitter:card" content="summary_large_image" />
        {/* {twitterUsername && <meta name="twitter:creator" content={twitterUsername} />} */}
        <meta name="twitter:title" content={title} />
        <meta name="twitter:description" content={description} />
        <meta name="twitter:image" content={image} />
      </Helmet>
      {props.language === 'en' ? <GlobalStyleEnglish /> : <GlobalStyleHebrew />}
      {props.children}
    </>
  )
}

export default DefaultLayout
