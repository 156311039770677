import { css } from 'styled-components'

import AlefBold from '../../fonts/alef/Alef-Bold.ttf'
import AlefRegular from '../../fonts/alef/Alef-Regular.ttf'

import AvenirNextBold from '../../fonts/avenir-next/AvenirNext-Bold.ttf'
import AvenirNextDemiBold from '../../fonts/avenir-next/AvenirNext-DemiBold.ttf'
import AvenirNextMedium from '../../fonts/avenir-next/AvenirNext-Medium.ttf'
import AvenirNextRegular from '../../fonts/avenir-next/AvenirNext-Regular.ttf'

import WorkSansBold from '../../fonts/work-sans/WorkSans-Bold.ttf'
import WorkSansMedium from '../../fonts/work-sans/WorkSans-Medium.ttf'
import WorkSansRegular from '../../fonts/work-sans/WorkSans-Regular.ttf'
import WorkSansSemiBold from '../../fonts/work-sans/WorkSans-SemiBold.ttf'

/* http://meyerweb.com/eric/tools/css/reset/ 
   v2.0 | 20110126
   License: none (public domain)
*/
export const generalGlobalStyle = css`
  html,
  body,
  div,
  span,
  applet,
  object,
  iframe,
  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  p,
  blockquote,
  pre,
  a,
  abbr,
  acronym,
  address,
  big,
  cite,
  code,
  del,
  dfn,
  em,
  img,
  ins,
  kbd,
  q,
  s,
  samp,
  small,
  strike,
  strong,
  sub,
  sup,
  tt,
  var,
  b,
  u,
  i,
  center,
  dl,
  dt,
  dd,
  ol,
  ul,
  li,
  fieldset,
  form,
  label,
  legend,
  table,
  caption,
  tbody,
  tfoot,
  thead,
  tr,
  th,
  td,
  article,
  aside,
  canvas,
  details,
  embed,
  figure,
  figcaption,
  footer,
  header,
  hgroup,
  menu,
  nav,
  output,
  ruby,
  section,
  summary,
  time,
  mark,
  audio,
  video {
    margin: 0;
    padding: 0;
    border: 0;
    font-size: 100%;
    font: inherit;
    vertical-align: baseline;
  }
  /* HTML5 display-role reset for older browsers */
  article,
  aside,
  details,
  figcaption,
  figure,
  footer,
  header,
  hgroup,
  menu,
  nav,
  section {
    display: block;
  }
  body {
    line-height: 1;
  }
  ol,
  ul {
    list-style: none;
  }
  blockquote,
  q {
    quotes: none;
  }
  blockquote:before,
  blockquote:after,
  q:before,
  q:after {
    content: '';
    content: none;
  }
  table {
    border-collapse: collapse;
    border-spacing: 0;
  }

  @font-face {
    font-family: 'Avenir Next';
    font-style: normal;
    font-weight: 400;
    src: url(${AvenirNextRegular}) format('truetype');
    font-display: swap;
  }

  @font-face {
    font-family: 'Avenir Next';
    font-style: normal;
    font-weight: 500;
    src: url(${AvenirNextMedium}) format('truetype');
    font-display: swap;
  }

  @font-face {
    font-family: 'Avenir Next';
    font-style: normal;
    font-weight: 600;
    src: url(${AvenirNextDemiBold}) format('truetype');
    font-display: swap;
  }

  @font-face {
    font-family: 'Avenir Next';
    font-style: normal;
    font-weight: 700;
    src: url(${AvenirNextBold}) format('truetype');
    font-display: swap;
  }

  @font-face {
    font-family: 'Work Sans';
    font-style: normal;
    font-weight: 400;
    src: url(${WorkSansRegular}) format('truetype');
    font-display: swap;
  }

  @font-face {
    font-family: 'Work Sans';
    font-style: normal;
    font-weight: 500;
    src: url(${WorkSansMedium}) format('truetype');
    font-display: swap;
  }

  @font-face {
    font-family: 'Work Sans';
    font-style: normal;
    font-weight: 600;
    src: url(${WorkSansSemiBold}) format('truetype');
    font-display: swap;
  }

  @font-face {
    font-family: 'Work Sans';
    font-style: normal;
    font-weight: 700;
    src: url(${WorkSansBold}) format('truetype');
    font-display: swap;
  }

  @font-face {
    font-family: Alef;
    font-style: normal;
    font-weight: 400;
    src: url(${AlefRegular}) format('truetype');
    font-display: swap;
  }

  @font-face {
    font-family: Alef;
    font-style: normal;
    font-weight: 700;
    src: url(${AlefBold}) format('truetype');
    font-display: swap;
  }

  :root {
    --blue: #367bf5;
    /* --blue: #4285f4; */
    --gray-blue: #3f3d56;
    --gray: #545274;
    --gary-dark: #bbbbbb;
    --light-gray: #fafafa;
    --off-white: #f2f2f2;
    --black: #2f2e41;
  }

  *,
  body {
    -ms-text-size-adjust: 100%;
    -webkit-text-size-adjust: 100%;
  }

  body {
    color: var(--black);
  }

  /* 
  * https://dev.to/hzburki/100-height-to-all-divs-gatsby-33nd
  * Purpose:
  * Assign height: "100%" to
  * html, body, #___gatsby &  
  * div with role="group"
  */

  html,
  body,
  #___gatsby,
  #gatsby-focus-wrapper {
    height: 100%;
  }

  body {
    margin: 0px;
  }

  div[role='group'][tabindex] {
    height: 100%;
    /* to push footer to the bottom of the screen on short pages */
    display: flex;
    flex-direction: column;
  }
`
