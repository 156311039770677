import { createGlobalStyle } from 'styled-components'
import { generalGlobalStyle } from './GlobalStyle'

export const GlobalStyleEnglish = createGlobalStyle`
  ${generalGlobalStyle}
  
  body {
    direction: ltr;
  }

  *, body {
    font-family: 'Avenir Next', 'Open Sans', 'Raleway', sans-serif;
  }
`
