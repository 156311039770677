const REFERRER_SEARCH_KEY = 'r'
export const SEARCH_KEY = 'search_query'

const REFERRER_MAP = {
  a: 'Amelia',
  e: 'Eliezer',
  l: 'Leeann',
  s: 'Luky',
}

const getReferrerValue = () => window.localStorage.getItem(REFERRER_SEARCH_KEY)
export const getSearchValue = () => window.localStorage.getItem(SEARCH_KEY)
export const getReferrer = (): string | undefined => REFERRER_MAP[getReferrerValue()]
