import { useEffect } from 'react'
import { SEARCH_KEY } from '../utils/referrers'

export const useSearchParams = () => {
  useEffect(() => {
    const search = window.location.search
    const urlParams = new URLSearchParams(window.location.search)

    if (search && !window.localStorage.getItem(SEARCH_KEY)) localStorage.setItem(SEARCH_KEY, search)

    urlParams.forEach((value, key) => {
      if (!window.localStorage.getItem(key)) localStorage.setItem(key, value)
    })
  }, [])
}
