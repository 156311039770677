import { createGlobalStyle } from 'styled-components'
import { generalGlobalStyle } from './GlobalStyle'

export const GlobalStyleHebrew = createGlobalStyle`
  ${generalGlobalStyle}

  body {
    direction: rtl;
  }

  *, body {
    font-family: Alef, 'Avenir Next', sans-serif;
  }
`
